import React, {useEffect, useRef, useState} from 'react';
import styles from './styles.module.scss'
import useOutsideClick from "../../hooks/dom/useOutsideClick";
import Input from "../Input/Input";
import arrow from '../../assets/images/symbols/arrow.svg';
import plus from '../../assets/images/symbols/plus.svg';
import arrowWhite from '../../assets/images/symbols/arrow_white.svg';
import avatarPlaceholder from '../../assets/images/avatar.svg';
import serchIcon from '../../assets/images/symbols/Search.svg';
import cn from "classnames";
import { useSelector } from 'react-redux';
import useFindTranslations from '../../hooks/useFindTranlsations';
import useFindTranslationsByString from '../../hooks/useFindTranslationsByString';

const Dropdown = ({
                      data,
                      placeholder,
                      onChange,
                      value,
                      field = null,
                      title = null,
                      isWhiteBlack = false,
                      createBtnText,
                      onOpenCreateModal,
                      searchNeeded,
                      maxHeight,
                      isError,
                      isUsers,
                      testStatuses,
                      checkDropdownStatus,
                      maxHeightFrontEl,
                      noTranslate,
                      setSearchedValue,
                      searchedValue
                  }) => {
    const [isOpen, setIsOpen] = useState(false)
    const [searchValue, setSearchValue] = useState('');

    const t = useFindTranslationsByString();

    const currentCompanyId = useSelector(state => state.sidebar.companyId);

    const dropdownRef = useRef()
    const inputRef = useRef()
    const inputRefTop = useRef()

    const onClose = () => {
      setIsOpen(false);
      if(checkDropdownStatus) checkDropdownStatus(false);
    } 
    
    const onToggle = () => {
      if(checkDropdownStatus) checkDropdownStatus(!isOpen);
      setIsOpen(!isOpen);
    }

    const onChangeValue = (val) => onChange(val);

    useOutsideClick(dropdownRef, onClose, inputRef);

    const searchHandler = (list) => {
      return list?.filter(item => searchValue?.length ? item?.name?.toLowerCase()?.includes(searchValue?.toLowerCase()) : true); 
      }

   const filteredData = searchHandler(data);

   useEffect(()=> {
      if(!isOpen && !setSearchedValue) {
         setSearchValue('');
      } 
      if(!isOpen && setSearchedValue) {
         setSearchedValue('');
      } 
      if(isOpen) inputRefTop.current.focus();
   }, [isOpen])

   const translateField = (item, field) => {
      if (noTranslate) {
        return item[field] || item; 
      }
      
      return field ? t(item[field]) : t(item);
    };


    return (
        <>
            {title && <p className={styles.title}>{title}</p>}
            <div className={cn(styles.dropdown_wrapper, value?.length && searchNeeded ? styles.value : '')} onClick={onToggle} ref={inputRef}>
               {(searchNeeded || createBtnText) && isOpen &&
                  <img className={styles.search} src={serchIcon} alt="" />
               }
               {createBtnText || searchNeeded ? (
                  <Input
                     isDefaultColor
                     placeholder={value || placeholder}
                     ref={inputRefTop}
                     customClass={cn(isOpen ? styles.input_open : null, isOpen && (searchNeeded || createBtnText) ? styles.padding_left : '')}
                     disabled={!isOpen}
                     value={searchValue}
                     onChangeValue={(e) => {
                        if(setSearchedValue) {
                           setSearchedValue();
                        } else {
                           setSearchValue(e.target.value)
                        }
                     }}
                     isWhiteBlack={isWhiteBlack}
                     isError={isError ? styles.error : ''}
                  />
               ) : (
                  <Input
                     placeholder={placeholder}
                     ref={inputRefTop}
                     customClass={isOpen ? styles.input_open : null}
                     disabled={true}
                     value={noTranslate ? value : t(value || '')}
                     onChangeValue={(e) => setSearchValue(e.target.value)}
                     isWhiteBlack={isWhiteBlack}
                     isError={isError ? styles.error : ''}
                     maxHeightFrontEl={maxHeightFrontEl}
                  />
               )}

               <div className={styles.click_el}></div>
               
                <img src={isWhiteBlack ? arrowWhite : arrow} alt={''}
                     className={cn(styles.arrow, isOpen && styles.arrow_open)}/>

                {isOpen && <div style={{maxHeight: maxHeight ? maxHeight : '300px'}} className={cn(styles.dropdown, isWhiteBlack && styles.dropdown_isWhiteBlack, createBtnText && styles.with_create_btn)}>
                    {filteredData?.map((item, key) => {
                        const classNameActive = field
                            ? cn(styles.dropdown_item, item?.id === value?.id && styles.dropdown_item_active)
                            : cn(styles.dropdown_item, item === value && styles.dropdown_item_active);

                            if(isUsers) {
                              return (
                              <div key={key} onClick={() => onChangeValue(item)} className={classNameActive}>
                                    <div className={styles.user_info_wrapper}>
                                          <img 
                                             src={`${item.avatar ? item.avatar : avatarPlaceholder}`}
                                             alt="" 
                                          />
                                          <div className={styles.right}>
                                            <p>{field ? item[field] : item}</p>
                                            <span>{item?.userBranchRoles?.filter(role => role?.branch?.company?.id === +currentCompanyId)?.[0]?.role?.name?.toLowerCase().replace(/_/g, ' ').replace('role', '') || ''}</span>
                                          </div>
                                    </div>
                              </div>
                              )
                            }

                        return (
                            <div key={key} onClick={() => onChangeValue(item)} className={cn(classNameActive, testStatuses ? styles[`status_${item.status}`] : '', styles.drop_item)}>
                                {translateField(item, field)}
                            </div>
                        )
                    })}
                    {!data.length &&
                     <div className={styles.dropdown_item}>
                        {t("There are no data")}
                     </div>
                    }
                         {createBtnText && isOpen && (
                           <div onClick={onOpenCreateModal} className={styles.create_btn}>
                              <img className={styles.plus} src={plus} alt="" />
                              {noTranslate ? createBtnText : t(createBtnText)}
                           </div>
                        )}
                </div>}
            
            </div>
        </>
    );
};

export default Dropdown;