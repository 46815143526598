import React, { useEffect, useState } from "react";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import styles from "./styles.module.scss";
import cn from "classnames";
import BodyItem from "./BodyItem";
import TeamApi from "../../../utils/api/TeamApi";
import { useParams } from "react-router-dom";
import EmptyBlock from "../../../components/EmptyBlock/EmptyBlock";
import useFindTranslationsByString from "../../../hooks/useFindTranslationsByString";

const DeparAndPosTable = ({
   columns,
   data,
   searchedValue,
   tableFor,
   refetchUsers,
   isLoading,
   onRemoveAccess,
   lastItemRef,
   order,
   setOrder,
   orderBy,
   setOrderBy,
   isDepartments,
   onClickDeleteIcon,
   onClickEditIcon,
}) => {
   const [userIdTodeleteFromPlatform, setUserIdTodeleteFromPlatform] =
      useState(null);
   const [userIdTodeleteFromTeam, setUserIdTodeleteFromTeam] = useState(null);
   const [isChangeLoading, setIsChangeLoading] = useState(false);

   const t = useFindTranslationsByString();
   const params = useParams();

   const handleSort = (column) => {
      const columnMappings = {
         department: { orderBy: isDepartments ? "name" : "department", orderToggle: true },
         position: { orderBy: isDepartments ? "position" : "name", orderToggle: true },
         users: { orderBy: "total_users", orderToggle: true },
      };

      const columnMapping = columnMappings[column];
      if (columnMapping) {
         const { orderBy, orderToggle } = columnMapping;
         setOrderBy(orderBy || "");
         if (orderToggle) {
            setOrder(order === "asc" ? "desc" : "asc");
         }
      }
   };

   if (!data?.length && searchedValue?.length > 0) {
      return <EmptyBlock text={"data"} />;
   }

   return (
      <div className={styles.table_container}>
         <table className={styles.table}>
            <thead className={styles.thead}>
               <tr>
                  {columns.map((column, index) => (
                     <th
                        key={column}
                        onClick={() => handleSort(column)}
                        className={cn(
                           styles.header,
                           column === "actions" ? styles.actions_column : ""
                        )}
                     >
                        <span>{t(column.replace(/_/g, " "))}</span>
                        {column !== "actions" && (
                           <div className={styles.arrow_wrapper}>
                              <span className={styles.arrow}></span>
                           </div>
                        )}
                     </th>
                  ))}
               </tr>
            </thead>
            <tbody className={styles.tbody}>
               <TransitionGroup component={null}>
                  {data?.map((row, index) => (
                     <CSSTransition
                        key={`row-${index}`}
                        classNames="fade"
                        timeout={100}
                     >
                        <>
                           <BodyItem
                              row={row}
                              data={data}
                              columns={columns}
                              index={index}
                              onClickDeleteIcon={onClickDeleteIcon}
                              onClickEditIcon={onClickEditIcon}
                              onRemoveAccess={onRemoveAccess}
                              isDepartments={isDepartments}
                              lastItemRef={lastItemRef}
                              t={t}
                           />
                        </>
                     </CSSTransition>
                  ))}
               </TransitionGroup>
            </tbody>
         </table>

         {data?.length === 0 && !isLoading && !searchedValue?.length && (
            <div>
               <EmptyBlock text={t("There are no data")} />
            </div>
         )}
      </div>
   );
};

export default DeparAndPosTable;
