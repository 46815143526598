import { Routes, Route } from "react-router-dom";
import SignUpPage from "../templates/SignUpPage/SignUpPage";
import { pathList } from "./path";
import { localStorageGet } from "../utils/localStorage";
import LoginPage from "../templates/LoginPage/LoginPage";
import HomePage from "../templates/HomePage/HomePage";
import CoursesPage from "../templates/CoursesPage/CoursesPage";
import CoursesSettingsPage from "../templates/CoursesSettingsPage/CoursesSettingsPage";
import CourseCreationPage from "../templates/CourseCreationPage/CourseCreationPage";
import AgenciesPage from "../templates/AgenciesPage/AgenciesPage";
import AgencySettingsPage from "../templates/AgencySettingsPage/AgencySettingsPage";
import MainLayout from "../layouts/MainLayout/MainLayout";
import UsersPage from "../templates/UserPage/UsersPage";
import LessonSettingPage from "../templates/LessonSettingsPage/LessonSettingsPage";
import KnowledgeCheckPage from "../templates/KnowlegeCheckPage/KnowledgeCheckPage";
import ActualKnowledgeCheck from "../templates/KnowlegeCheckPage/ActualKnowledgeCheck/ActualKnowledgeCheck";
import ActualKnowledgeCheckStudent from "../templates/StudentPerspectivePages/ActualKnowledgeCheckStudent/ActualKnowledgeCheckStudent";
import UsersRights from "../templates/UsersRights/UsersRights";
import FolderPage from "../templates/CoursesPage/FolderPage";
import TopicPage from "../templates/CoursesPage/TopicPage";
import TeamPage from "../templates/TeamPage/TeamPage";
import CourseContentPage from "../templates/StudentPerspectivePages/CourseContentPage/CourseContentPage";
import UserProfileAdminView from "../templates/UserProfileAdminView/UserProfileAdminView";
import ProfilePage from "../templates/ProfilePage/ProfilePage";
import VerifiedPage from "../templates/VerifiedPage/VerifiedPage";
import ResetPasswordPage from "../templates/ResetPasswordPage/ResetPasswordPage";
import StatisticsPage from "../templates/StatisticsPage/StatisticsPage";
import StatisticsUserInfoPage from "../templates/StatisticsUserInfoPage/StatisticsUserInfoPage";
import FolderSettingsPage from "../templates/FolderSettingsPage/FolderSettingsPage";
import LanguagesPage from "../templates/LanguagesPage/LanguagesPage";
import DepartmentsAndPositionsPage from "../templates/DepartmentsAndPositionsPage/DepartmentsAndPositionsPage";

export default function Routers() {
    return (
        <Routes>

            {localStorageGet('token') 
                ? <Route path={"/"} element={<HomePage/>}/>
                : <Route path="/" element={<LoginPage/>}/>
            }

            {/*AUTH*/}
            <Route path={pathList.signup.path} element={<SignUpPage/>}/>
            <Route path={pathList.login.path} element={<LoginPage/>}/>

            {/*MAIN*/}
            <Route path={pathList.home.path} element={<HomePage/>}/>
            <Route path={pathList.coursesSettings.path} element={<CoursesSettingsPage/>}/>
            <Route path={pathList.coursesCreate.path} element={<CourseCreationPage/>}/>
            <Route path={pathList.coursesCreateWithModal.path} element={<CourseCreationPage modal />}/>
            <Route path={pathList.coursesEditOld.path} element={<CourseCreationPage/>}/>

            <Route path={pathList.courses.path} element={<CoursesPage/>}/>
            <Route path={pathList.coursesWithFilter.path} element={<CoursesPage/>}/>
            <Route path={pathList.agencies.path} element={<AgenciesPage/>}/>
            <Route path={pathList.agencySettings.path} element={<AgencySettingsPage/>}/>
            <Route path={pathList.lessonSettings.path} element={<LessonSettingPage/>}/>

            <Route path={pathList.users.path} element={<UsersPage/>}/>
            <Route path={pathList.team.path} element={<TeamPage/>}/>
            <Route path={pathList.userProfile.path} element={<ProfilePage />}/>
            <Route path={pathList.userProfileAdminView.path} element={<UserProfileAdminView />}/>
            <Route path={pathList.userProfileAdminViewFromTeam.path} element={<UserProfileAdminView />}/>

            <Route path={pathList.knowledgeCheck.path} element={<KnowledgeCheckPage/>}/>
            <Route path={pathList.knowledgeCheckActual.path} element={<ActualKnowledgeCheck/>}/>

            <Route path={pathList.knowledgeCheckActualStudent.path} element={<ActualKnowledgeCheckStudent/>}/>
            <Route path={pathList.verified.path} element={<VerifiedPage/>}/>

            <Route path={pathList.usersRights.path} element={<UsersRights/>}/>
            <Route path={pathList.folder.path} element={<FolderPage/>}/>
            <Route path={pathList.folder_settings.path} element={<FolderSettingsPage/>}/>
            <Route path={pathList.topic_settings.path} element={<FolderSettingsPage/>}/>

            <Route path={pathList.topic.path} element={<TopicPage/>}/>
            <Route path={pathList.statistics.path} element={<StatisticsPage />}/>
            <Route path={pathList.statistics_user_info.path} element={<StatisticsUserInfoPage />}/>
            <Route path={pathList.languagesPage.path} element={<LanguagesPage />}/>
            <Route path={pathList.companyLanguagesPage.path} element={<LanguagesPage />}/>

            {/* USER PERSPECTIVE CourseContentPage */}
            <Route path={pathList.coursesContent.path} element={<CourseContentPage/>}/>
            <Route path={pathList.coursesContentOld.path} element={<CourseContentPage/>}/>
            <Route path={pathList.coursesContentFromAdminView.path} element={<CourseContentPage/>}/>
            <Route path={`${pathList.departmentsAndPositions.path}`} element={<DepartmentsAndPositionsPage/>}/>

            <Route path={pathList.resetPassword.path} element={<ResetPasswordPage/>}/>
            
            {/* NOT FOUND PAGE */}
            <Route path="*" element={
                     <MainLayout>
                           <div style={{  color: "white", 
                                          padding: '20px',
                                          fontWeight: "bold" }}> 
                              Page not found 
                           </div>
                     </MainLayout>
                  }
            />
        </Routes>
    )
}